import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  OrganizationUnitDTO,
  OrganizationUnitFormDTO,
} from '../dto/organization-unit.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitService {
  private apiUrl = environment.apiUrl; // Use the apiUrl from environment

  constructor(private http: HttpClient) {}

  getOrganizationUnits(
    level?: string,
    sortField: string = 'title_en',
    sortOrder: string = 'asc',
    searchQuery?: string,
    is_primary: boolean = false,
    parent_unit_ids?: string
  ): Observable<OrganizationUnitDTO[]> {
    let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder)
      .set('primary', is_primary);

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }
    if (level) {
      params = params.set('level', level);
    }
    if (parent_unit_ids) {
      params = params.set('parent_unit_ids', parent_unit_ids);
    }

    return this.http.get<OrganizationUnitDTO[]>(
      this.apiUrl + '/organization-units',
      {
        params,
      }
    );
  }

  getOrganizationUnit(id: number): Observable<OrganizationUnitDTO> {
    return this.http.get<OrganizationUnitDTO>(
      `${this.apiUrl}/organization-units/${id}`
    );
  }

  addOrganizationUnit(
    organizationUnit: OrganizationUnitFormDTO
  ): Observable<OrganizationUnitFormDTO> {
    return this.http.post<OrganizationUnitFormDTO>(
      this.apiUrl + '/organization-units',
      organizationUnit
    );
  }

  updateOrganizationUnit(
    id: number,
    organizationUnit: OrganizationUnitFormDTO
  ): Observable<OrganizationUnitFormDTO> {
    return this.http.put<OrganizationUnitFormDTO>(
      `${this.apiUrl}/organization-units/${id}`,
      organizationUnit
    );
  }

  activateOrganizationUnits(ids: number[]): Observable<any> {
    return this.http.put(`${this.apiUrl}/organization-units/activate`, {
      ids: ids,
    });
  }

  deactivateOrganizationUnits(ids: number[]): Observable<any> {
    return this.http.put(`${this.apiUrl}/organization-units/deactivate`, {
      ids: ids,
    });
  }

  deleteOrganizationUnits(ids: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/organization-units/delete`, {
      ids: ids,
    });
  }
}
